/*

     _________________________________     O---o                        __  __     _
    |         _  _            _  __   |     O-o       __ _   ___  _ __  \ \/ /  __| |  ___ __   __
    |     /\ |_)|_)|   /\ | |(_ |_    |      O       / _` | / _ \| '_ \  \  /  / _` | / _ \\ \ / /
    ;   _/``\|  |  |__/``\|_| _)|__   |     o-O     | (_| ||  __/| | | | /  \ | (_| ||  __/ \ V /
   ((_(-____________.-.___________.)`)┘    o---O     \__, | \___||_| |_|/_/\_\ \__,_| \___|  \_/
    \__ )        ,'     `.        \ _/     O---o     |___/
    :  :        |_________|       :  :      O-o
    |-'|       ,'-.-.--.-.`.      |`-|       O       Hi! This code is optimized.
    |_.|      (( (*  )(*  )))     |._|      o-O      Unoptimized website  @ https://source.genxdev.net/
    |  |       `.-`-'--`-'.'      |  |     o---O     allows you to step through Typescript and set
    |-'|        | ,-.-.-. |       |._|     O---o     breakpoints.
    |  |        |(|-|-|-|)|       |  |      O-o
    :,':        |_`-'-'-'_|       ;`.;       O       Application : 'genXdev.net'
     \  \     ,'           `.    /._/       o-O      Filename    : 'main.tsx'
      \/ `._ /_______________\_,'  /       o---O
        `.| |  |           |  |,'╔═════════════════╡@license genXdev All Rights Reserved╞═╗
          `.|  |           |  |  ║(the "Software") are the exclusive property of genXdev  ║
╔═══════════╡  |           |  ╞══╝Unauthorized copying, distribution, modification, or use╚══════════════════╗
║of this Software, in whole or in part, is strictly prohibited. For permission requests, please contact:     ║
║genXdev, info@genXdev.net Written permission from genXdev is required for any use of this Software,         ║
║including but not limited to, reproduction, adaptation, distribution, performance, display, or the creation ║
║of derivative works based upon the Software. THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND,║
║EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR║
║PURPOSE, TITLE, AND NON-INFRINGEMENT. IN NO EVENT SHALL GENXDEV BE LIABLE FOR ANY CLAIM, DAMAGES, OR OTHER  ║
║LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT, OR OTHERWISE, ARISING FROM, OUT OF, OR IN CONNECTION WITH║
║THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.╔═════════════════════════════════════════════════╝
╚══════════════════════════════════════════════════════════╝

*/

import { RegisterServiceWorker } from "../../../../shared/typescript/AppHelpers/AppServiceWorkerHelper.js";
import { AppEventHandler } from "../../../../shared/typescript/AppComponents/AppEventHandler/AppEventHandler.js";

export class main extends React.Component {
  public render() {
    return (
      //<AppEventHandler>

      <div
        id="catalog"
        title="catalog, an index of genXdev projects"
      >
      <iframe src="https://source.genxdev.net/catalog" title="catalog" />
      </div>

      //</AppEventHandler>
    );
  }
}

ReactDOM.render(React.createElement(main), document.querySelector("#content"));

if (window.opener) {
  try {

    window.opener!.postMessage({ su: "genxdev" }, '*');

    let onVisibilityChanged = function (e) {

      if (!document.hidden) {
        window.opener!.postMessage({ su: "genxdev" }, '*');
      }
    };

    document.removeEventListener("visibilitychange", onVisibilityChanged);
    document.addEventListener("visibilitychange", onVisibilityChanged, {
      passive: false,
    });
  } catch (e) { }
}

RegisterServiceWorker(true, true);

function includeHTML(): void {
  let z: HTMLCollectionOf<HTMLDivElement> =
    document.getElementsByTagName("div");

  for (let i: number = 0; i < z.length; i++) {
    let elmnt: HTMLDivElement = z[i];
    let file: string | null = elmnt.getAttribute("include-html");

    if (!!file) {
      let xhttp: XMLHttpRequest = new XMLHttpRequest();

      xhttp.onreadystatechange = function () {
        if (this.readyState === 4) {
          if (this.status === 200) {
            elmnt.innerHTML = this.responseText;
          }

          elmnt.removeAttribute("include-html");
        }
      };

      xhttp.open("GET", file, true);
      xhttp.send();
    }
  }
}

includeHTML();